@import '../../../../scss/theme-bootstrap';

.gnav-account {
  &__content-container {
    &.register-active {
      .gnav-account__content-container-forms__signin-container {
        display: none;
      }
    }
    &-signed-in {
      display: none;
      .is_signed_in & {
        display: block;
        font-size: 12px;
        overflow-y: auto;
        padding: 32px 44px 48px;
      }
    }
    &__toggle {
      display: inline-block;
      font-size: 12px;
      line-height: get-line-height(12px, 12px);
      padding: 32px 44px 0;
      text-transform: uppercase;
      width: 100%;
      &-link {
        color: $color-grey;
        text-align: $ldirection;
        text-decoration: none;
      }
      &-signin {
        padding-#{$rdirection}: 40px;
        .signin-active & {
          color: $color-black;
          font-weight: bold;
          text-decoration: underline;
        }
      }
      &-register {
        .register-active & {
          color: $color-black;
          font-weight: bold;
          text-decoration: underline;
        }
      }
      .is_signed_in & {
        display: none;
      }
    }
    &-forms {
      .is_signed_in & {
        display: none;
      }
      font-size: 12px;
      overflow-y: auto;
      padding: 0 44px 48px;
      &-use-data {
        padding-top: 24px;
        position: relative;
        width: 100%;
        &:hover > .gnav-account__content-container-forms-use-data-overlay {
          display: block;
        }
        .icon--information {
          display: inline-block;
          height: 22px;
          width: 22px;
        }
        &-overlay {
          background-color: $color-black;
          bottom: 30px;
          color: $color-white;
          display: none;
          line-height: get-line-height(12px, 12px);
          padding: 15px 20px;
          position: absolute;
          a {
            color: $color-white;
            text-decoration: underline;
          }
        }
      }
      &-account-links {
        display: none;
        .is_signed_in & {
          display: block;
          li {
            font-size: 12px;
            line-height: get-line-height(12px, 12px);
            padding-bottom: 15px;
            a {
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      &__signin-container,
      &__register-container {
        &-signin-with {
          font-weight: bold;
          line-height: get-line-height(12px, 12px);
          margin: 0;
          padding-top: 32px;
        }
      }
      &__signin-container-form,
      &__register-mobile-form,
      &__register-sms-form {
        border-bottom: 1px solid $color-cream-stroke;
        padding-bottom: 48px;
        input[type='submit'] {
          outline: none;
          width: 100%;
        }
        input[type='text'],
        input[type='password'] {
          font-size: 12px;
          line-height: normal;
          &.error {
            border: 1px solid $color-error;
          }
        }
        &-forgot-password {
          line-height: get-line-height(12px, 12px);
          padding-bottom: 32px;
        }
        &-field {
          display: block;
          margin: 0 auto 24px;
          width: 100%;
        }
      }
      &__register-mobile-form {
        border-bottom: 0;
        overflow: auto;
        padding-bottom: 24px;
        input[type='tel'] {
          float: #{$ldirection};
          height: 48px;
          outline: none;
          width: 66%;
        }
        input[type='submit'] {
          float: #{$rdirection};
          outline: none;
          width: auto;
        }
      }
      &__register-sms-form {
        input[type='password'] {
          margin-bottom: 20px;
          width: 100%;
        }
      }
      &__register-container-form {
        &-register-terms {
          input[type='checkbox'] {
            &.error {
              & + span {
                &:before {
                  border: 1px solid $color-error;
                }
              }
            }
          }
        }
        &-submit {
          margin-top: 32px;
        }
      }
      &__signin {
        ul.error_messages {
          display: block;
          margin: 0 !important; // It is needed to override style which comes from PG.
          padding-bottom: 24px;
          &.error_messages_display {
            display: block;
          }
          li {
            background: none;
            color: $color-error;
            padding: 0;
          }
        }
        .signin-active & {
          display: block;
        }
      }
      &__register {
        display: none;
        .register-active & {
          display: block;
        }
      }
    }
    &-forms__register-container-form-register-terms,
    &-forms__register-container-form-register-email-promotion,
    .social-login__email-opt-in {
      input[type='checkbox'] ~ .label,
      input[type='checkbox'] ~ label {
        @include swap_direction(padding, 5px 0 0 35px);
        font-size: 12px;
        line-height: get-line-height(12px, 15px);
      }
    }
  }
}
